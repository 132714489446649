<template>
  <!--background Area Start-->
  <div class="backgrount-area fixed-bg-1 pt-110 pb-110 overlay-dark-fullwidth">
    <div class="banner-content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="text-content-wrapper text-center full-width">
              <div class="text-content text-center">
                <h1 class="title1 cd-headline push text-center border-none mt-40">
                  <span class="cd-words-wrapper">
                    <b class="is-visible"> الصفحة غير متوفرة </b>
                  </span>
                </h1>
                <div class="banner-readmore">
                  <router-link to="/" class="button-default button-green"
                    >الرئيسية</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End of background Area-->
</template>

<script>
export default {
  mounted() {
    window.scroll(0, 0);
    document.getElementById("VueMainJsNewTouch")?.remove();
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "./js/main.js");
    recaptchaScript.id = "VueMainJsNewTouch";
    document.head.appendChild(recaptchaScript);
  },
};
</script>

<style scoped>
.overlay-dark-fullwidth {
  z-index: 0;
}
</style>
