<template>
  <div class="breadcrumb-banner-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="breadcrumb-text">
            <h1 class="text-left">الاخبار</h1>
            <div class="breadcrumb-bar">
              <ul class="breadcrumb text-left">
                <li><router-link to="/">الرئيسية</router-link></li>
                <li>الاخبار</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--News Details Area Start-->
  <div class="news-details-area section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-12 col-12">
          <div class="news-details-content">
            <div class="single-latest-item">
               <img v-bind:src="imgUniv" />
              <div class="single-latest-text">
                  <h3 id="TitleUniv"></h3>             
                <p id="DetailsUniv"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-12">
          <div class="sidebar-widget">
            <div class="single-sidebar-widget">
              <h4 class="title">اخر الاخبار</h4>
            <div class="recent-content">
                <div
                  v-for="news in News"
                  :key="news.postId"
                  class="recent-content-item"
                >
                 <router-link  
                        :to="{
                          name: 'NewsDetails-with-id-And-Type',
                          params: {
                            type: $route.params.type,
                            id: news.postID,
                          },
                        }"
                      ><img  @click="getDataNews(news.postID,$route.params.type)"
                      class="img_left"
                      v-bind:src="
                        'http://api.yg-u.net/images/post/' + news.postImage
                      "
                  /> </router-link>
                  <div class="recent-text">
                    <p @click="getDataNews(news.postID,$route.params.type)">
                      <router-link  
                        :to="{
                          name: 'NewsDetails-with-id-And-Type',
                          params: {
                            type: $route.params.type,
                            id: news.postID,
                          },
                        }"
                      >
                        {{ news.postTitle }}
               
                      </router-link>
                    </p>
                    <!-- <p>   {{ news.postSubTitle }}</p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <ul class="tags">
                <li><router-link  :to="{
                          name: 'News-With-Type',
                          params: {
                            type: $route.params.type,
                          },
                        }">عرض أرشيف الاخبار</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End of News Details Area-->
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      show: true,
      News: [],
      Title:String,
      Details:String,
      imgUniv:String
    };
  },
  methods: {
    getDataNews(PostID, Type) {
      
      var slef = this;

        slef.show = true;

      window.scroll(0, 0);
      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPostById");
      bodyFormData.append("PostId",PostID)
      bodyFormData.append("Lang", "ar");
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", Type);
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "1");
      axios({
        method: "post",
        url: "http://api.yg-u.net/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
            var arr = response.data
          if(arr.length !=0){
          slef.imgUniv =  "http://api.yg-u.net/images/post/" + response.data[0]['postImage'];
          document.getElementById("TitleUniv").innerText = response.data[0]['postTitle']
          document.getElementById("DetailsUniv").innerHTML = response.data[0]['postDetails']
          }

            slef.show = false;
        })
        .catch(function (response) {
          console.log("error", response);
            slef.show = false;
        });
    },
  },
  mounted() {
    
    window.scroll(0, 0);
    var slef = this;
   slef.getDataNews(this.$route.params.id,this.$route.params.type)
    var bodyFormData = new FormData();
    bodyFormData.append("check", "getPost");
    bodyFormData.append("Lang", "ar");
     bodyFormData.append("PostId", "");
    bodyFormData.append("FbrnId", "");
    bodyFormData.append("Type", this.$route.params.type);
    bodyFormData.append("ParentId", "NTROOT0");
    bodyFormData.append("PostName", "");
    bodyFormData.append("Pno", "10");
    axios({
      method: "post",
      url: "http://api.yg-u.net/our_team/getPost",
      data: bodyFormData,
    })
      .then(function (response) {
        slef.News = response.data;
        document.getElementById("VueMainJsNewTouch")?.remove();
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute("src", "../../../js/main.js");
        recaptchaScript.id = "VueMainJsNewTouch";
        document.head.appendChild(recaptchaScript);

        setTimeout(() => {
                                      slef.show = false;
                                    }, 100);

      })
      .catch(function (response) {
        console.log("error", response);
      });
  },
};
</script>

<style scoped>
.title {
  width: 100% !important;
  text-align: right !important ;
  background: #fff !important ;
  position: relative !important ;
}
.single-sidebar-widget .tags li a {
  background: #3498db none repeat scroll 100% 0;
  border: 1px solid #3498db;
  color: #ffffff;
  float: right;
  margin-bottom: 13px;
  margin-left: 6px;
  padding: 11px 35px;
  position: absolute;
}
.img_left {
  height: 70px !important;
  widows: 70px im !important;
}
.recent-text p {
  font-size: 15px;
  line-height: 23px;
}
.single-latest-item img{
  width: 870px;
  height: 435px;
}
.news-details-content .single-latest-text {
    padding: 28px 20px 0px;
    text-align: justify;
}
</style>
