<template>
  <div class="loader" v-show="show">
    <div class="loader-inner">
      <img class="ImgLoading" src="../../public/img/logo/footer.png" />
      <img
        style="margin-top: 61px; margin-right: 10px"
        src="../../public/img/three-dots.svg"
      />
    </div>
  </div>

  <div class="slider-area slider-two">
    <div class="preview-2">
      <div id="nivoslider" class="slides">
        <div v-for="slider in sliders" :key="slider.postId">
          <img
            v-bind:src="'http://api.yg-u.net/images/post/' + slider.postImage"
            v-bind:title="'#' + slider.postId"
          />
        </div>
      </div>

      <div v-for="slider in sliders" :key="slider.postId">
        <div v-bind:id="slider.postId" class="nivo-html-caption nivo-caption">
          <div class="banner-content slider-1">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="text-content-wrapper">
                    <div class="text-content">
                      <h1 class="title1">{{ slider.postTitle }}</h1>
                      <p style="text-align: justify" class="sub-title d-none d-lg-block">
                        {{ slider.postSubTitle }}
                      </p>
                      <div class="banner-readmore">
                        <a href="javascript:void(0)" class="button-default bg-blue"
                          >التفاصيل</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="newsletter-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 d-none d-lg-block">
          <div class="newsletter-content">
            <h3 id="h__1">اخر اخبار الجامعة</h3>
          </div>
        </div>
        <div class="col-lg-9 col-md-9">
          <div dir="ltr">
            <marquee direction="right">
              <span v-for="newstape in NewsTape" :key="newstape.postId">
                <img
                  v-bind:src="'http://api.yg-u.net/images/post/' + newstape.postImage"
                />
                <a target="_blank" :href="newstape.postSubTitle">
                  {{ newstape.postTitle }}</a
                >
              </span>
            </marquee>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-page-area section-padding" style="padding: 56px 0">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper" style="margin-bottom: 19px">
            <div class="section-title">
              <h3>{{ AboutName }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="about-text-container">
            <p>
              {{ AboutSub }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="latest-area section-padding"
    style="background-color: #3498db; padding: 60px 0 67px"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3 style="margin-bottom: 24px; color: #fff">تعرف على الجامعة</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-for="KnowUniv in KnowUniv"
          :key="KnowUniv.postId"
          class="col-lg-4 col-md-6"
        >
          <div class="single-event-item">
            <div class="single-event-image">
              <a href="javascript:void(0)">
                <img
                  v-bind:src="'http://api.yg-u.net/images/post/' + KnowUniv.postImage"
                />
              </a>
            </div>
            <div class="single-event-text">
              <h6 style="margin-bottom: 10px">
                <a href="javascript:void(0)">{{ KnowUniv.postTitle }}</a>
              </h6>
              <p>{{ KnowUniv.postSubTitle }}</p>

              <router-link
                class="button-default"
                :to="{
                  name: 'Univ-with-id-And-Type',
                  params: {
                    type: 'Univ.Home.Sec.KnowUniv',
                    id: KnowUniv.postID,
                  },
                }"
              >
                التفاصيل
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fun-factor-area" style="padding: 60px 0 67px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper white">
            <div class="section-title">
              <h3 style="margin-bottom: 24px">حقائق مهمة</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="Facts in Facts" :key="Facts.postId" class="col-lg-3 col-md-3 col-12">
          <div class="single-fun-factor">
            <h4>{{ Facts.postTitle }}</h4>
            <h2>
              <span class="counter">{{ Facts.postSubTitle }}</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="latest-area section-padding" style="padding: 60px 0 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3 style="margin-bottom: 24px">أخر أخبار الجامعة</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-for="news in News"
          :key="news.postId"
          class="col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <div class="single-latest-item">
            <div class="single-latest-image">
              <a href="javascript:void(0)"
                ><img
                  class="imagesNews_"
                  v-bind:src="'http://api.yg-u.net/images/post/' + news.postImage"
                  alt=""
              /></a>
            </div>
            <div class="single-latest-text">
              <h6 style="margin-bottom: 20px">
                <a href="javascript:void(0)">{{ news.postTitle }}</a>
              </h6>
              <p>
                {{ news.postSubTitle }}
              </p>
              <router-link
                class="button-default"
                :to="{
                  name: 'NewsDetails-with-id-And-Type',
                  params: {
                    type: 'Univ.Home.Sec.News',
                    id: news.postID,
                  },
                }"
              >
                التفاصيل
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="testimonial-area section-margin" style="padding: 60px 0 60px">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 offset-lg-0 col-md-12 col-12" dir="rtl">
          <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
              <div class="testimonial-image-slider text-center">
                <div
                  v-for="testimonials in Testimonials"
                  :key="testimonials.postId"
                  class="sin-testiImage"
                >
                  <img
                    v-bind:src="
                      'http://api.yg-u.net/images/post/' + testimonials.postImage
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="testimonial-text-slider text-center" dir="rtl">
            <div
              v-for="testimonials in Testimonials"
              :key="testimonials.postId"
              class="sin-testiText"
            >
              <h2>{{ testimonials.postTitle }}</h2>
              <p>
                {{ testimonials.postSubTitle }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="latest-area section-padding" style="padding: 0px 0 60px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3 style="margin-bottom: 24px">انشطة الجامعة</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="event in Events" :key="event.postId" class="col-lg-4 col-md-6 col-12">
          <div class="single-event-item">
            <div class="single-event-image">
              <a href="javascript:void(0)">
                <img
                  v-bind:src="'http://api.yg-u.net/images/post/' + event.postImage"
                />
                <span
                  ><span>{{ event.day1 }}</span
                  >{{ event.nT_MONTH1 }}</span
                >
              </a>
            </div>
            <div class="single-event-text">
              <h6>
                <a href="javascript:void(0)" style="line-height: 2; text-align: justify"
                  >{{ event.postTitle }}
                </a>
              </h6>
              <div class="single-item-comment-view">
                <span><i class="zmdi zmdi-time"></i>{{ event.time1 }}</span>
                <span><i class="zmdi zmdi-pin"></i>{{ event.txtAdress1 }} </span>
              </div>
              <p style="text-align: justify">
                {{ event.postSubTitle }}
              </p>

              <router-link
                class="button-default"
                :to="{
                  name: 'Event-with-id-And-Type',
                  params: {
                    name: 'الأنشطة',
                    type: 'Univ.Activity',
                    id: event.postID,
                  },
                }"
              >
                التفاصيل
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      show: true,
      sliders: [],
      NewsTape: [],
      AboutName: String,
      AboutSub: String,
      KnowUniv: [],
      Facts: [],
      News: [],
      Testimonials: [],
      Events: [],
    };
  },
  mounted() {
    window.scroll(0, 0);
    var slef = this;
    var bodyFormData = new FormData();
    bodyFormData.append("check", "getPost");
    bodyFormData.append("PostId", "");
    bodyFormData.append("Lang", "ar");
    bodyFormData.append("FbrnId", "");
    bodyFormData.append("Type", "Univ.Home.MainSlider");
    bodyFormData.append("ParentId", "NTROOT0");
    bodyFormData.append("PostName", "");
    bodyFormData.append("Pno", "5");
    axios({
      method: "post",
      url: "http://api.yg-u.net/our_team/getPost",
      data: bodyFormData,
    })
      .then(function (response) {
        slef.sliders = response.data;
        var bodyFormData = new FormData();
        bodyFormData.append("check", "getPost");
        bodyFormData.append("PostId", "");
        bodyFormData.append("Lang", "ar");
        bodyFormData.append("FbrnId", "");
        bodyFormData.append("Type", "Univ.Home.Sec.About");
        bodyFormData.append("ParentId", "NTROOT0");
        bodyFormData.append("PostName", "");
        bodyFormData.append("Pno", "1");
        axios({
          method: "post",
          url: "http://api.yg-u.net/our_team/getPost",
          data: bodyFormData,
        })
          .then(function (response) {
            var arr = response.data;
            if (arr.length != 0) {
              slef.AboutName = response.data[0]["postTitle"];
              slef.AboutSub = response.data[0]["postSubTitle"];
            }
            var bodyFormData = new FormData();
            bodyFormData.append("check", "getPost");
            bodyFormData.append("PostId", "");
            bodyFormData.append("Lang", "ar");
            bodyFormData.append("FbrnId", "");
            bodyFormData.append("Type", "Univ.Home.Sec.KnowUniv");
            bodyFormData.append("ParentId", "NTROOT0");
            bodyFormData.append("PostName", "");
            bodyFormData.append("Pno", "3");
            axios({
              method: "post",
              url: "http://api.yg-u.net/our_team/getPost",
              data: bodyFormData,
            })
              .then(function (response) {
                slef.KnowUniv = response.data;
                var bodyFormData = new FormData();
                bodyFormData.append("check", "getPost");
                bodyFormData.append("PostId", "");
                bodyFormData.append("Lang", "ar");
                bodyFormData.append("FbrnId", "");
                bodyFormData.append("Type", "Univ.Home.Sec.Facts");
                bodyFormData.append("ParentId", "NTROOT0");
                bodyFormData.append("PostName", "");
                bodyFormData.append("Pno", "8");
                axios({
                  method: "post",
                  url: "http://api.yg-u.net/our_team/getPost",
                  data: bodyFormData,
                })
                  .then(function (response) {
                    slef.Facts = response.data;
                    var bodyFormData = new FormData();
                    bodyFormData.append("check", "getPost");
                    bodyFormData.append("PostId", "");
                    bodyFormData.append("Lang", "ar");
                    bodyFormData.append("FbrnId", "");
                    bodyFormData.append("Type", "Univ.Home.Sec.News");
                    bodyFormData.append("ParentId", "NTROOT0");
                    bodyFormData.append("PostName", "");
                    bodyFormData.append("Pno", "4");
                    axios({
                      method: "post",
                      url: "http://api.yg-u.net/our_team/getPost",
                      data: bodyFormData,
                    })
                      .then(function (response) {
                        slef.News = response.data;
                        var bodyFormData = new FormData();
                        bodyFormData.append("check", "getPost");
                        bodyFormData.append("PostId", "");
                        bodyFormData.append("Lang", "ar");
                        bodyFormData.append("FbrnId", "");
                        bodyFormData.append("Type", "Univ.Home.Sec.Testimonials");
                        bodyFormData.append("ParentId", "NTROOT0");
                        bodyFormData.append("PostName", "");
                        bodyFormData.append("Pno", "6");
                        axios({
                          method: "post",
                          url: "http://api.yg-u.net/our_team/getPost",
                          data: bodyFormData,
                        })
                          .then(function (response) {
                            slef.Testimonials = response.data;
                            var bodyFormData = new FormData();
                            bodyFormData.append("check", "getPost");
                            bodyFormData.append("Lang", "ar");
                            bodyFormData.append("FbrnId", "");
                            bodyFormData.append("Type", "Univ.Activity");
                            bodyFormData.append("ParentId", "NTROOT0");
                            bodyFormData.append("PostName", "");
                            bodyFormData.append("Pno", "3");
                            axios({
                              method: "post",
                              url: "http://api.yg-u.net/our_team/getPost",
                              data: bodyFormData,
                            })
                              .then(function (response) {
                                slef.Events = response.data;
                                var bodyFormData = new FormData();
                                bodyFormData.append("check", "getPost");
                                bodyFormData.append("Lang", "ar");
                                bodyFormData.append("FbrnId", "");
                                bodyFormData.append("Type", "Univ.Home.NewsLine");
                                bodyFormData.append("ParentId", "NTROOT0");
                                bodyFormData.append("PostName", "");
                                bodyFormData.append("Pno", "10");
                                axios({
                                  method: "post",
                                  url: "http://api.yg-u.net/our_team/getPost",
                                  data: bodyFormData,
                                })
                                  .then(function (response) {
                                    slef.NewsTape = response.data;
                                    document
                                      .getElementById("VueMainJsNewTouch")
                                      ?.remove();
                                    let recaptchaScript = document.createElement(
                                      "script"
                                    );
                                    recaptchaScript.setAttribute("src", "/js/main.js");
                                    recaptchaScript.id = "VueMainJsNewTouch";
                                    document.head.appendChild(recaptchaScript);
                                    setTimeout(() => {
                                      slef.show = false;
                                    }, 100);
                                  })
                                  .catch(function (response) {
                                    console.log("error", response);
                                  });
                              })
                              .catch(function (response) {
                                console.log("error", response);
                              });
                          })
                          .catch(function (response) {
                            console.log("error", response);
                          });
                      })
                      .catch(function (response) {
                        console.log("error", response);
                      });
                  })
                  .catch(function (response) {
                    console.log("error", response);
                  });
              })
              .catch(function (response) {
                console.log("error", response);
              });
          })
          .catch(function (response) {
            console.log("error", response);
          });
      })
      .catch(function (response) {
        console.log("error", response);
      });
  },
};
</script>

<style scoped>
.slider-area {
  z-index: 0;
}

.newsletter-area {
  background: #3498db none repeat scroll 100% 0;
  color: #ffffff;
  position: relative;
}

.newsletter-form {
  overflow: hidden;
  padding: 7px 0;
}

.newsletter-area:before {
  height: 65px;
  right: 0;
  left: auto;
  top: -13px;
  width: 27%;
}

.newsletter-area:after {
  border-width: 32.5px 27px;
  right: 27%;
  left: auto;
  top: -13px;
}

.newsletter-content {
  padding-top: 0px;
  z-index: 9;
  position: relative;
}

.ticker-left {
  display: inline-block;
  font-weight: bold;
  min-width: 50%;
  margin-top: 0px;
  margin-bottom: -1rem !important;
}

marquee {
  margin-top: 7px;
}

marquee img {
  height: 27px;
  width: 40px;
  padding-right: 5px;
  padding-left: 5px;
}
marquee a {
  color: #fff;
}

.single-event-image span {
  display: block;
  font-family: "montserratsemi_bold";
  font-size: 26px;
  text-align: center;
}
.imagesNews_ {
  width: 236px;
  height: 234px;
}
</style>
