<template>
  <div class="loader" v-show="show">
      <div class="loader-inner">
        <img class="ImgLoading" src="../../public/img/logo/footer.png" />
        <img
          style="margin-top: 61px; margin-right: 10px"
          src="../../public/img/three-dots.svg"
        />
      </div>
    </div>
  <div class="breadcrumb-banner-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="breadcrumb-text">
            <h1 class="text-left">أرشيف الاخبار</h1>
            <div class="breadcrumb-bar">
              <ul class="breadcrumb text-left">
                <li>   <router-link to="/">الرئيسية</router-link></li>
                <li>أرشيف الاخبار</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
    <div class="latest-area section-padding" style="padding: 60px 0 0px">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
          </div>
        </div>
        <div class="row">
          <div
            v-for="news in News"
            :key="news.postId"
            class="col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <div class="single-latest-item">
              <div class="single-latest-image">
                <a href="javascript:void(0)"
                  ><img class="imagesNews_"
                    v-bind:src="
                      'http://api.yg-u.net/images/post/' + news.postImage
                    "
                    alt=""
                /></a>
              </div>
              <div class="single-latest-text">
                <h6 style="margin-bottom: 20px">
                  <a href="javascript:void(0)">{{ news.postTitle }}</a>
                </h6>
                <p>
                  {{ news.postSubTitle }}
                </p>
              
                       <router-link  class="button-default"
                        :to="{
                          name: 'NewsDetails-with-id-And-Type',
                          params: {
                            type: $route.params.type,
                            id: news.postID,
                          },
                        }"
                      >
                     التفاصيل
               
                      </router-link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
       show: true,
      News: [],
    };
  },
  mounted() {
    window.scroll(0, 0);
    var slef = this;
    var bodyFormData = new FormData();
    bodyFormData.append("check", "getPost");
    bodyFormData.append("Lang", "ar");
    bodyFormData.append("FbrnId", "");
    bodyFormData.append("Type", this.$route.params.type);
    bodyFormData.append("ParentId", "NTROOT0");
    bodyFormData.append("PostName", "");
    bodyFormData.append("Pno", "10");
    axios({
      method: "post",
      url: "http://api.yg-u.net/our_team/getPost",
      data: bodyFormData,
    })
      .then(function (response) {
        slef.News = response.data;
        document.getElementById("VueMainJsNewTouch")?.remove();
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute("src", "./js/main.js");
        recaptchaScript.id = "VueMainJsNewTouch";
        document.head.appendChild(recaptchaScript);

          setTimeout(() => {
                                      slef.show = false;
                                    }, 100);
      })
      .catch(function (response) {
        console.log("error", response);
      });
  },
};
</script>

<style scoped>
.imagesNews_{
  width: 236px;
  height: 234px;
}
</style>