<template>
  <div class="loader" v-show="show">
    <div class="loader-inner">
      <img class="ImgLoading" src="../../public/img/logo/footer.png" />
      <img
        style="margin-top: 61px; margin-right: 10px"
        src="../../public/img/three-dots.svg"
      />
    </div>
  </div>

  <div class="breadcrumb-banner-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
        <div class="breadcrumb-text">
            <h1 class="text-center" id="postTitleId"></h1>
            <p id="postSubTitleId" class="text-center"></p>
          </div> 
        </div>
      </div>
    </div>
  </div>
  <div class="about-page-area section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="about-text-container">
            <p id="postDetailsId"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data(){
    return{
           show: true,
    }
  },
    mounted() {
        window.scroll(0, 0);
        var bodyFormData = new FormData();
        var slef = this;
        bodyFormData.append("check", "getPostById");
        bodyFormData.append("Lang", "ar");
        bodyFormData.append("PostId", this.$route.params.id)
        bodyFormData.append("FbrnId", "");
        bodyFormData.append("Type", this.$route.params.type);
        bodyFormData.append("ParentId", "NTROOT0");
        bodyFormData.append("PostName", "");
        bodyFormData.append("Pno", "10");
        axios({
                method: "post",
                url: "http://api.yg-u.net/our_team/getPost",
                data: bodyFormData,
            })
            .then(function (response) {
                 var arr = response.data
          if(arr.length !=0){
                document.getElementById('postTitleId').innerText = response.data[0]['postTitle']
                document.getElementById('postSubTitleId').innerText = response.data[0]['postSubTitle']
                document.getElementById('postDetailsId').innerHTML = response.data[0]['postDetails']
          }
                document.getElementById("VueMainJsNewTouch")?.remove();
                let recaptchaScript = document.createElement("script");
                recaptchaScript.setAttribute("src", "../../js/main.js");
                recaptchaScript.id = "VueMainJsNewTouch";
                document.head.appendChild(recaptchaScript);

                      setTimeout(() => {
                                      slef.show = false;
                                    }, 100);

            })
            .catch(function (response) {
                console.log("error", response);
            });
    },

};
</script>


<style scoped>
.about-text-container p {
    text-align: justify;
}
.breadcrumb-text p {
     text-align: justify;
}
.breadcrumb-text h1:after {
   background: #576573;
    bottom: 0;
    content: "";
    height: 29px;
    right: 0;
    margin: auto;
    position: absolute;
    left: 0;
    width: 2px;
}
.section-padding {
    padding: 50px 0px;
}
</style>
