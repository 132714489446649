<template>
  <div id="nav">
    <header>
      <div class="header-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-5 d-none d-lg-block d-md-block">
              <span
                ><i
                  class="zmdi zmdi-balance"
                  style="padding-left: 7px; font-size: 16px"
                ></i>
                {{ Welecom }}
              </span>
            </div>
            <div class="col-lg-5 col-md-7 col-12">
              <div class="header-top-right">
                <div class="content">
                  <router-link to="/">
                    <i class="zmdi zmdi-pin"></i>
                    {{ Addrees }}
                  </router-link>
                </div>
                <div class="content">
                  <router-link to="/">
                    <i class="zmdi zmdi-phone"></i>
                    {{ phones }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-logo-menu sticker">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-12">
              <div class="logo">
                <router-link to="/">
                  <img v-bind:src="TopLogoUrl" alt="YemenGulf" />
                </router-link>
              </div>
            </div>
            <div class="col-lg-9 col-12">
              <div class="mainmenu-area pull-left" style="text-align: left">
                <div class="mainmenu d-none d-lg-block">
                  <nav>
                    <ul id="nav">
                      <li class="current">
                        <router-link to="/">الرئيسية</router-link>
                      </li>
                      <li>
                       <a href="javascript:void(0)" >عن الجامعة</a>
                        <ul class="sub-menu" style="width: 280px">
                          <li v-for="leader in Leader" :key="leader.postId">
                            <router-link
                              :to="{
                                name: 'Leader-with-id-And-Type',
                                params: {
                                  type: 'Univ.Home.Leader',
                                  id: leader.postID,
                                },
                              }"
                            >
                              {{ leader.postTitle }}<i :class="leader.icon1"></i
                            ></router-link>
                          </li>

                          <li v-for="aboutdata in AboutData" :key="aboutdata.postId">
                            <router-link
                              :to="{
                                name: 'Page-with-id-And-Type',
                                params: {
                                  type: 'Univ.Home.AboutMenue',
                                  id: aboutdata.postID,
                                },
                              }"
                            >
                              {{ aboutdata.postTitle }}<i :class="aboutdata.icon1"></i
                            ></router-link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        
                        <a href="javascript:void(0)" >الكليات</a>
                        <ul class="sub-menu" style="width: 288px">
                          <li v-for="faculty in Faculty" :key="faculty.BrnId">
                            <router-link
                              :to="{
                                name: 'Faculties-with-id',
                                params: {
                                  id: faculty.BrnId,
                                },
                              }"
                            >
                              {{ faculty.Faculty }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
<!--
                      <li class="current">
                        <router-link to="/ComingSoon">المكتبة</router-link>
                      </li>
-->
                      <li>
                        <a href="javascript:void(0)">المراكز</a>

                        <ul class="sub-menu" style="width: 280px">
                          <li v-for="centerdata in CenterData" :key="centerdata.postId">
                            <router-link
                              :to="{
                                name: 'Page-with-id-And-Type',
                                params: {
                                  type: 'Univ.Q.Center',
                                  id: centerdata.postID,
                                },
                              }"
                            >
                              {{ centerdata.postTitle }}<i :class="centerdata.icon1"></i
                            ></router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="current">
                        <router-link to="/ComingSoon">بوابة الطالب</router-link>
                      </li>

                      <li>
                        <router-link to="/ComingSoon">تواصل معنا</router-link>
<!--
                        <ul class="sub-menu">
                          <li>
                            <router-link to="/ComingSoon"
                              >الموقع الجغرافى<i class="zmdi zmdi-pin font_color"></i
                            ></router-link>
                          </li>
                          <li>
                            <router-link to="/ComingSoon"
                              >ارقام التواصل<i class="zmdi zmdi-phone font_color"></i
                            ></router-link>
                          </li>
                          <li>
                            <router-link to="/ComingSoon">
                              ارسال رسالة<i class="zmdi zmdi-comment-list font_color"></i
                            ></router-link>
                          </li>
                        </ul>
-->
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile Menu Area start -->
      <div class="mobile-menu-area">
        <div class="container clearfix">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="mobile-menu">
                <nav id="dropdown">
                  <ul dir="rtl">
                    <li class="current">
                      <router-link to="/">الرئيسية</router-link>
                    </li>

                    <li>
                     <a href="javascript:void(0)" >عن الجامعة</a>
                      <ul>
                           <li v-for="leader in Leader" :key="leader.postId">
                            <router-link
                              :to="{
                                name: 'Leader-with-id-And-Type',
                                params: {
                                  type: 'Univ.Home.Leader',
                                  id: leader.postID,
                                },
                              }"
                            >
                              {{ leader.postTitle }}</router-link>
                          </li>

                          <li v-for="aboutdata in AboutData" :key="aboutdata.postId">
                            <router-link
                              :to="{
                                name: 'Page-with-id-And-Type',
                                params: {
                                  type: 'Univ.Home.AboutMenue',
                                  id: aboutdata.postID,
                                },
                              }"
                            >
                              {{ aboutdata.postTitle }}
                          </router-link>
                          </li>
                      </ul>
                    </li>

                    <li>
                      <a href="javascript:void(0)" >الكليات</a>
                      <ul class="sub-menu">
                        <li v-for="faculty in Faculty" :key="faculty.BrnId">
                          <router-link
                            :to="{
                              name: 'Faculties-with-id',
                              params: {
                                id: faculty.BrnId,
                              },
                            }"
                          >
                            {{ faculty.Faculty }}
                          </router-link>
                        </li>
                      </ul>
                    </li>

                    <li class="current">
                      <router-link to="/ComingSoon">المكتبة</router-link>
                    </li>

                      <li>
                        <a href="javascript:void(0)">المراكز</a>

                        <ul class="sub-menu">
                          <li v-for="centerdata in CenterData" :key="centerdata.postId">
                            <router-link
                              :to="{
                                name: 'Page-with-id-And-Type',
                                params: {
                                  type: 'Univ.Q.Center',
                                  id: centerdata.postID,
                                },
                              }"
                            >
                              {{ centerdata.postTitle }}
                            ></router-link>
                          </li>
                        </ul>
                      </li>

                    <li class="current">
                      <router-link to="/ComingSoon">بوابة الطالب</router-link>
                    </li>

                    <li>
                      <router-link to="/ComingSoon">تواصل معنا</router-link>
                      <ul class="sub-menu">
                        <li>
                          <router-link to="/ComingSoon">الموقع الجغرافى</router-link>
                        </li>
                        <li><router-link to="/ComingSoon">ارقام التواصل</router-link></li>
                        <li><router-link to="/ComingSoon"> ارسال رسالة</router-link></li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <router-view :key="$route.fullPath"></router-view>

    <div class="newsletter-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <div class="newsletter-content">
              <h3 id="h__1">{{ postTitle }} </h3>
              <h2 id="h__1">{{ postSubTitle }}</h2>
            </div>
          </div>
          <div class="col-lg-7 col-md-7">
            <div class="newsletter-form angle">
              <form action="#" id="mc-form" class="mc-form footer-newsletter fix">
                <div class="subscribe-form">
                  <input
                    id="mc-email "
                    type="email "
                    name="email "
                    placeholder="ادخل البريد الالكترونى"
                  />
                  <button id="mc-submit " type="submit">الإشتراك</button>
                </div>
              </form>
              <div class="mailchimp-alerts text-centre fix pull-left">
                <div class="mailchimp-submitting"></div>
                <div class="mailchimp-success"></div>
                <div class="mailchimp-error"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-widget-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="single-footer-widget">
              <div class="footer-logo">
                <router-link to="/ComingSoon">
                  <img v-bind:src="bottomLogoUrl" alt=" "
                /></router-link>
              </div>
              <p id="postDetails"></p>
              <div class="social-icons">
                <a target="_blank" :href="facebook"><i class="zmdi zmdi-facebook"></i></a>
                <a :href="instgram" target="_blank"
                  ><i class="zmdi zmdi-instagram"></i
                ></a>
                <a :href="twitter" target="_blank"><i class="zmdi zmdi-twitter"></i></a>
                <a :href="youtube" target="_blank"><i class="zmdi zmdi-youtube"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="single-footer-widget">
              <h3>ابقى على تواصل</h3>
              <a href="tel:0123-456-789 "><i class="fa fa-phone"></i>{{ f1 }}</a>
              <a href="tel:0123-456-789 "><i class="fa fa-phone"></i>{{ f2 }}</a>
              <a href="tel:0123-456-789 "><i class="fa fa-phone"></i>{{ f3 }}</a>
              <span><i class="fa fa-envelope"></i>{{ f4 }}</span>
              <span><i class="fa fa-globe"></i>{{ f5 }}</span>
              <span><i class="fa fa-map-marker"></i>{{ f6 }}</span>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="single-footer-widget">
              <h3>تصفح ايضاً</h3>
              <ul class="footer-list">
                <li><router-link to="/">الرئيسية</router-link></li>

                <li v-for="leader in Leader" :key="leader.postId">
                  <router-link
                    :to="{
                      name: 'Leader-with-id-And-Type',
                      params: {
                        type: 'Univ.Home.Leader',
                        id: leader.postID,
                      },
                    }"
                  >
                    {{ leader.postTitle }}</router-link
                  >
                </li>

                <li v-for="aboutdata in AboutData" :key="aboutdata.postId">
                  <router-link
                    :to="{
                      name: 'Page-with-id-And-Type',
                      params: {
                        type: 'Univ.Home.AboutMenue',
                        id: aboutdata.postID,
                      },
                    }"
                  >
                    {{ aboutdata.postTitle }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="single-footer-widget">
              <h3></h3>
              <div class="instagram-image">
                <div v-for="gellery in Gellery" :key="gellery.postId" class="footer-img">
                  <a href="#">
                    <img
                      v-bind:src="
                        'http://api.yg-u.net/images/post/' + gellery.postImage
                      "
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-7 col-12">
            <span
              >كافة الحقوق محفوظة لدى جامعة اليمن والخليج للعلوم والتكنولوجيا‏ 2021</span
            >
          </div>
          <div class="col-lg-6 col-md-5 col-12">
            <div class="column-right">
              <span
                >Developed by<a
                  class="link_newtouch"
                  href="https://newtouch.dev/"
                  target="_blank"
                >
                  NewTouch</a
                >Smart Technology Solutions
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      TopLogoUrl: String,
      Welecom: String,
      Addrees: String,
      phones: String,
      bottomLogoUrl: String,
      FooterData: [],
      AboutData: [],
      Leader: [],
      CenterData: [],
      Gellery: [],
      facebook: String,
      instgram: String,
      linkedIn: String,
      youtube: String,
      postTitle: String,
      postSubTitle: String,
      twitter: String,
      f1: String,
      f2: String,
      f3: String,
      f4: String,
      f5: String,
      f6: String,
      Faculty: [],
    };
  },
  mounted() {
    var slef = this;
    var bodyFormData = new FormData();
    bodyFormData.append("check", "getPost");
    bodyFormData.append("Lang", "ar");
    bodyFormData.append("FbrnId", "");
    bodyFormData.append("Type", "Univ.Header.Data");
    bodyFormData.append("ParentId", "NTROOT0");
    bodyFormData.append("PostName", "");
    bodyFormData.append("Pno", "1");
    axios({
      method: "post",
      url: "http://api.yg-u.net/our_team/getPost",
      data: bodyFormData,
    })
      .then(function (response) {
         var arr =  response.data
         console.log( response.data)
         if(arr.length != 0)
         {
        slef.TopLogoUrl =
          "http://api.yg-u.net/images/post/" + response.data[0]["postImage"];
        slef.Welecom = response.data[0]["f1"];
        slef.Addrees = response.data[0]["f2"];
        slef.phones = response.data[0]["f3"];
         }
        var bodyFormData = new FormData();
        bodyFormData.append("check", "getPostDetails");
        bodyFormData.append("Lang", "ar");
        bodyFormData.append("FbrnId", "");
        bodyFormData.append("Type", "Univ.Footer.Data");
        bodyFormData.append("ParentId", "NTROOT0");
        bodyFormData.append("PostName", "");
        bodyFormData.append("Pno", "1");
        axios({
          method: "post",
          url: "http://api.yg-u.net/our_team/getPost",
          data: bodyFormData,
        })
          .then(function (response) {
            slef.FooterData = response.data;
            if( slef.FooterData.length !=0)
            {
            slef.bottomLogoUrl =
              "http://api.yg-u.net/images/post/" + slef.FooterData[0]["postImage"];

            slef.facebook = slef.FooterData[0]["facebook1"];
            slef.instgram = slef.FooterData[0]["instgram1"];
            slef.linkedIn = slef.FooterData[0]["linkedIn1"];
            slef.twitter = slef.FooterData[0]["tweeter1"];
            slef.youtube = slef.FooterData[0]["youtube1"];
            slef.postTitle = slef.FooterData[0]["postTitle"];
            slef.postSubTitle = slef.FooterData[0]["postSubTitle"];
            slef.f1 = slef.FooterData[0]["f11"];
            slef.f2 = slef.FooterData[0]["f21"];
            slef.f3 = slef.FooterData[0]["f31"];
            slef.f4 = slef.FooterData[0]["f41"];
            slef.f5 = slef.FooterData[0]["f51"];
            slef.f6 = slef.FooterData[0]["f61"];
            document.getElementById("postDetails").innerHTML =
              slef.FooterData[0]["postDetails"];
            }
            var bodyFormData = new FormData();
            bodyFormData.append("check", "getPost");
            bodyFormData.append("Lang", "ar");
            bodyFormData.append("FbrnId", "");
            bodyFormData.append("Type", "Univ.Home.AboutMenue");
            bodyFormData.append("ParentId", "NTROOT0");
            bodyFormData.append("PostName", "");
            bodyFormData.append("Pno", "5");
            axios({
              method: "post",
              url: "http://api.yg-u.net/our_team/getPost",
              data: bodyFormData,
            })
              .then(function (response) {
                slef.AboutData = response.data;

                var bodyFormData = new FormData();
                bodyFormData.append("check", "getPost");
                bodyFormData.append("Lang", "ar");
                bodyFormData.append("FbrnId", "");
                bodyFormData.append("Type", "Univ.Home.Leader");
                bodyFormData.append("ParentId", "NTROOT0");
                bodyFormData.append("PostName", "");
                bodyFormData.append("Pno", "6");
                axios({
                  method: "post",
                  url: "http://api.yg-u.net/our_team/getPost",
                  data: bodyFormData,
                })
                  .then(function (response) {
                    slef.Leader = response.data;

                    var bodyFormData = new FormData();
                    bodyFormData.append("check", "getPost");
                    bodyFormData.append("Lang", "ar");
                    bodyFormData.append("FbrnId", "");
                    bodyFormData.append("Type", "Univ.Q.Center");
                    bodyFormData.append("ParentId", "NTROOT0");
                    bodyFormData.append("PostName", "");
                    bodyFormData.append("Pno", "10");
                    axios({
                      method: "post",
                      url: "http://api.yg-u.net/our_team/getPost",
                      data: bodyFormData,
                    })
                      .then(function (response) {
                        slef.CenterData = response.data;

                        var bodyFormData = new FormData();
                        bodyFormData.append("check", "getPost");
                        bodyFormData.append("Lang", "ar");
                        bodyFormData.append("FbrnId", "");
                        bodyFormData.append("Type", "Univ.Gellery");
                        bodyFormData.append("ParentId", "NTROOT0");
                        bodyFormData.append("PostName", "");
                        bodyFormData.append("Pno", "6");
                        axios({
                          method: "post",
                          url: "http://api.yg-u.net/our_team/getPost",
                          data: bodyFormData,
                        })
                          .then(function (response) {
                            slef.Gellery = response.data;

                            bodyFormData = new FormData();
                            bodyFormData.append("check", "");
                            bodyFormData.append("Lang", "ar");
                            bodyFormData.append("FbrnId", "U");
                            axios({
                              method: "post",
                              url: "http://api.yg-u.net/our_team/getMainSys",
                              data: bodyFormData,
                            })
                              .then(function (response) {
                                response.data.mainBrn.forEach((element) => {
                                  if (element.brnSys == "Univ.Faculty")
                                    slef.Faculty.push({
                                      BrnId: element.brnId,
                                      Faculty: element.brnName,
                                    });
                                });
                              })
                              .catch(function (response) {
                                console.log("error", response);
                              });
                          })
                          .catch(function (response) {
                            console.log("error", response);
                          });
                      })
                      .catch(function (response) {
                        console.log("error", response);
                      });
                  })
                  .catch(function (response) {
                    console.log("error", response);
                  });
              })
              .catch(function (response) {
                console.log("error", response);
              });
          })
          .catch(function (response) {
            console.log("error", response);
          });
      })
      .catch(function (response) {
        console.log("error", response);
      });
  },
};
</script>
<style scoped>
.font_color {
  color: #3498db;
}
.link_newtouch {
  color: #3498db;
  font-weight: bold;
}
li i {
  color: #3498db;
}
li a {
  font-weight: bold;
}
.footer-img {
  width: 80px;
  height: 80px;
}
.mean-container .mean-nav ul li a {
    background: #f8f8f8;
    color: #666;
    display: block;
    float: left;
    font-size: 12px;
    margin: 0;
    padding: 1em 5%;
    text-align: right  !important;
    text-decoration: none;
    text-transform: uppercase;
    width: 90%;
    font-weight: 700;
}
.mean-container .mean-nav ul li a.mean-expand {
    width: 26px;
    height: 15px;
    margin-top: 1px;
    padding: 12px!important;
    text-align: center;
    position: absolute;
    left: 0  !important;
    top: 0;
    z-index: 2;
    font-weight: 700;
    border: 0!important;
    background: #F8F8F8;
}
</style>
