<template>
 <div class="loader" v-show="show">
    <div class="loader-inner">
      <img class="ImgLoading" src="../../public/img/logo/footer.png" />
      <img
        style="margin-top: 61px; margin-right: 10px"
        src="../../public/img/three-dots.svg"
      />
    </div>
  </div>

  <div class="background-area overlay-dark-fullwidth no-animation">
    <img id="ImagesFID" />
    <div class="banner-content static-text">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="text-content-wrapper text-center full-width">
              <div class="text-content table-cell">
                <h1 class="title1 cd-headline push text-center border-none mt-40">
                  <span class="cd-words-wrapper">
                    <b id="postTitleId" class="is-visible"> </b>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contact-form-area section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-12">
          <!-- <h4 class="contact-title"></h4> -->
          <div class="contact-text">
            <p v-for="about in About" :key="about.postID">
              <span class="c-icon"><i class="zmdi zmdi-chevron-left"></i></span
              ><span class="c-text"
                ><a @click="getAboutById(about.postID)">{{ about.postTitle }}</a></span
              >
            </p>

            <p v-for="leader in Leader" :key="leader.postId">
              <span class="c-icon"><i class="zmdi zmdi-chevron-left"></i></span
              ><span class="c-text">
                <router-link
                  :to="{
                    name: 'Leader-with-id-And-Type',
                    params: {
                      type: 'Univ.Faculty.Leader',
                      id: leader.postID,
                    },
                  }"
                >
                  {{ leader.postTitle }}</router-link
                ></span
              >
            </p>
          </div>
        </div>
        <div class="col-lg-9 col-12">
          <div class="course-details-content">
            <div class="single-course-details">
              <div class="row">
                <div class="col-md-12">
                  <div class="single-item-text">
                    <h5 id="postTitle_"></h5>
                    <p id="postSubTitle_"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="about-area"
    v-bind:style="{
      'margin-top': '20px',
      background:
        'rgba(0, 0, 0, 0) url(http://api.yg-u.net/Images/post/' + ImageMess + ')',
    }"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="about-container">
            <h3>{{ TitleMess }}</h3>
            <p>
              {{ SubMess }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="event-area section-padding bg-white">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3 style="margin-bottom: 24px">أقسام الكلية</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="event in Events" :key="event.postId" class="col-lg-4 col-md-6 col-12">
          <div class="single-event-item">
            <div class="single-event-image">
              <a href="javascript:void(0)">
                <img
                  v-bind:src="'http://api.yg-u.net/images/post/' + event.postImage"
                />
                <span
                  ><span>{{ event.day1 }}</span
                  >{{ event.nT_MONTH1 }}</span
                >
              </a>
            </div>
            <div class="single-event-text">
              <h6>
                <a href="javascript:void(0)" style="line-height: 2; text-align: justify"
                  >{{ event.postTitle }}
                </a>
              </h6>
              <div class="single-item-comment-view">
                <span><i class="zmdi zmdi-time"></i>{{ event.time1 }}</span>
                <span><i class="zmdi zmdi-pin"></i>{{ event.txtAdress1 }} </span>
              </div>
              <p style="text-align: justify">
                {{ event.postSubTitle }}
              </p>

              <router-link
                class="button-default"
                :to="{
                  name: 'Event-with-id-And-Type',
                  params: {
                    brn: $route.params.id,
                    name: 'الاقسام',
                    type: 'Univ.Faculty.Activity',
                    id: event.postID,
                  },
                }"
              >
                التفاصيل
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="latest-area section-padding"
    style="background-color: #3498db; padding: 60px 0 0px"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3 style="margin-bottom: 24px; color: #fff">اخبار الكلية</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          v-for="news in News"
          :key="news.postId"
          class="col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <div class="single-latest-item">
            <div class="single-latest-image">
              <a href="javascript:void(0)"
                ><img
                  class="imagesNews_"
                  v-bind:src="'http://api.yg-u.net/images/post/' + news.postImage"
                  alt=""
              /></a>
            </div>
            <div class="single-latest-text">
              <h6 style="margin-bottom: 20px">
                <a href="javascript:void(0)">{{ news.postTitle }}</a>
              </h6>
              <p>
                {{ news.postSubTitle }}
              </p>
              <router-link
                class="button-default"
                :to="{
                  name: 'NewsDetails-with-id-And-Type',
                  params: {
                    type: 'Univ.Faculty.Home.Sec.News',
                    id: news.postID,
                  },
                }"
              >
                التفاصيل
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div class="contact-form-area section-padding">
    <div class="container">
         <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3 style="margin-bottom: 24px">مستعرض الصور</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-12">
          <!-- <h4 class="contact-title"></h4> -->
          <div class="contact-text">
            <p id="groupImages" v-for="galleryCategory in GalleryCategory" :key="galleryCategory.postID">
              <span class="c-icon"><i class="zmdi zmdi-collection-folder-image"></i></span
              ><span class="c-text"
                ><a @click="galleries(galleryCategory.postID)">{{ galleryCategory.postTitle }}</a></span
              >
            </p>
          </div>
        </div>
        <div class="col-lg-9 col-12">
          <div class="course-details-content" >
            <div class="single-course-details">
               <div class="row" id="gelery" >
        <div  v-for="gallery in Gallery" :key="gallery.postID"  class="col-lg-4 col-md-6 mb-30">
          <div class="gallery-img">
            <img v-bind:src="'http://api.yg-u.net/images/post/'+gallery.postImage" />
            <div class="hover-effect">
              <div class="zoom-icon">
                <a class="popup-image" :href="'http://api.yg-u.net/images/post/'+gallery.postImage"
                  ><i class="zmdi zmdi-zoom-in"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
        show: true,
      About: [],
      Leader: [],
      Events: [],
      News: [],
      ImageMess: String,
      TitleMess: String,
      SubMess: String,
      GalleryCategory:[],
      Gallery:[],
   
    };
  },
  methods: {
    getAboutById(id) {
      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPostById");
      bodyFormData.append("Lang", "ar");
      bodyFormData.append("PostId", id);
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Faculty.Home.About");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "1");
      axios({
        method: "post",
        url: "http://api.yg-u.net/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          document.getElementById("postTitle_").innerText = response.data[0]["postTitle"];
          document.getElementById("postSubTitle_").innerText =
            response.data[0]["postSubTitle"];
        })
        .catch(function (response) {
          console.log("error", response);
        });
    },
    galleries(id){
     var slef = this;
var bodyFormData = new FormData();
      bodyFormData.append("check", "getPostById");
      bodyFormData.append("Lang", "ar");
      bodyFormData.append("PostId", "");
      bodyFormData.append("FbrnId",this.$route.params.id);
      bodyFormData.append("Type", "Univ.Faculty.Gallery");
      bodyFormData.append("ParentId", id);
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "9");
      axios({
        method: "post",
        url: "http://api.yg-u.net/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
         slef.Gallery = response.data
        console.log('slef.Gallery',slef.Gallery)
        })
        .catch(function (response) {
          console.log("error", response);
        });
    }
  },
  mounted() {
    var slef = this;
    var ID = this.$route.params.id;
    window.scroll(0, 0);
    var bodyFormData = new FormData();
    bodyFormData.append("Lang", "ar");
    bodyFormData.append("Id", ID);

    axios({
      method: "post",
      url: "http://api.yg-u.net/branches/GetBrnWebSites",
      data: bodyFormData,
    })
      .then(function (response) {
           var BrnData
            BrnData = response.data
            if(BrnData.length !=0)
            {
        document.getElementById("postTitleId").innerText = response.data[0]["BrnTitle"];
        document
          .getElementById("ImagesFID")
          .setAttribute(
            "src",
            "http://api.yg-u.net/Images/branches/" + response.data[0]["BrnImage"]
          );
            }

        var bodyFormData = new FormData();

        bodyFormData.append("check", "getPost");
        bodyFormData.append("Lang", "ar");
        bodyFormData.append("PostId", "");
        bodyFormData.append("FbrnId", ID);
        bodyFormData.append("Type", "Univ.Faculty.Home.About");
        bodyFormData.append("ParentId", "NTROOT0");
        bodyFormData.append("PostName", "");
        bodyFormData.append("Pno", "20");
        axios({
          method: "post",
          url: "http://api.yg-u.net/our_team/getPost",
          data: bodyFormData,
        })
          .then(function (response) {
            slef.About = response.data;
            if(slef.About.length !=0)
            {
            slef.getAboutById(response.data[0]["postID"]);
            }
            var bodyFormData = new FormData();

            bodyFormData.append("check", "getPost");
            bodyFormData.append("Lang", "ar");
            bodyFormData.append("FbrnId", ID);
            bodyFormData.append("Type", "Univ.Faculty.Leader");
            bodyFormData.append("ParentId", "NTROOT0");
            bodyFormData.append("PostName", "");
            bodyFormData.append("Pno", "6");
            axios({
              method: "post",
              url: "http://api.yg-u.net/our_team/getPost",
              data: bodyFormData,
            })
              .then(function (response) {
                slef.Leader = response.data;

                var bodyFormData = new FormData();

                bodyFormData.append("check", "getPost");
                bodyFormData.append("Lang", "ar");
                bodyFormData.append("FbrnId", ID);
                bodyFormData.append("Type", "Univ.Faculty.Home.Message");
                bodyFormData.append("ParentId", "NTROOT0");
                bodyFormData.append("PostName", "");
                bodyFormData.append("Pno", "1");
                axios({
                  method: "post",
                  url: "http://api.yg-u.net/our_team/getPost",
                  data: bodyFormData,
                })
                  .then(function (response) {
                     var array
                     array =  response.data
                     
                    if(array.length !=0)
                    {
                    slef.ImageMess = response.data[0]["postImage"];
                    slef.TitleMess = response.data[0]["postTitle"];
                    slef.SubMess = response.data[0]["postSubTitle"];
                    }
                    var bodyFormData = new FormData();

                    bodyFormData.append("check", "getPost");
                    bodyFormData.append("Lang", "ar");
                    bodyFormData.append("FbrnId", ID);
                    bodyFormData.append("Type", "Univ.Faculty.Activity");
                    bodyFormData.append("ParentId", "NTROOT0");
                    bodyFormData.append("PostName", "");
                    bodyFormData.append("Pno", "3");
                    axios({
                      method: "post",
                      url: "http://api.yg-u.net/our_team/getPost",
                      data: bodyFormData,
                    })
                      .then(function (response) {
                        slef.Events = response.data;

                        var bodyFormData = new FormData();

                        bodyFormData.append("check", "getPost");
                        bodyFormData.append("Lang", "ar");
                        bodyFormData.append("FbrnId", ID);
                        bodyFormData.append("Type", "Univ.Faculty.Home.Sec.News");
                        bodyFormData.append("ParentId", "NTROOT0");
                        bodyFormData.append("PostName", "");
                        bodyFormData.append("Pno", "3");
                        axios({
                          method: "post",
                          url: "http://api.yg-u.net/our_team/getPost",
                          data: bodyFormData,
                        })
                          .then(function (response) {
                            slef.News = response.data;
                          
                             var bodyFormData = new FormData();

                        bodyFormData.append("check", "getPost");
                        bodyFormData.append("Lang", "ar");
                        bodyFormData.append("FbrnId", ID);
                        bodyFormData.append("Type", "Univ.Faculty.GalleryCategory");
                        bodyFormData.append("ParentId", "NTROOT0");
                        bodyFormData.append("PostName", "");
                        bodyFormData.append("Pno", "10");
                        axios({
                          method: "post",
                          url: "http://api.yg-u.net/our_team/getPost",
                          data: bodyFormData,
                        })
                          .then(function (response) {
                            slef.GalleryCategory = response.data;
                             if (slef.GalleryCategory.length != 0)
                            slef.galleries(response.data[0]['postID'])
                     
                                document.getElementById("VueMainJsNewTouch")?.remove();
                            let recaptchaScript = document.createElement("script");
                            recaptchaScript.setAttribute("src", "../../js/main.js");
                            recaptchaScript.id = "VueMainJsNewTouch";
                            document.head.appendChild(recaptchaScript);
                           

                                  setTimeout(() => {
                                      slef.show = false;
                                    }, 100);
     
                          })
                          .catch(function (response) {
                            console.log("error", response);
                          });

                          })
                          .catch(function (response) {
                            console.log("error", response);
                          });
                      })
                      .catch(function (response) {
                        console.log("error", response);
                      });
                  })
                  .catch(function (response) {
                    console.log("error", response);
                  });
              })
              .catch(function (response) {
                console.log("error", response);
              });
          })
          .catch(function (response) {
            console.log("error", response);
          });
      })
      .catch(function (response) {
        console.log("error", response);
      });
  },
};
</script>
<style scoped>
.newsletter-area {
  background: #3498db none repeat scroll 100% 0;
  color: #ffffff;
  position: relative;
}

.newsletter-form {
  overflow: hidden;
  padding: 7px 0;
}

.newsletter-area:before {
  height: 65px;
  right: 0;
  left: auto;
  top: -13px;
  width: 27%;
}

.newsletter-area:after {
  border-width: 32.5px 27px;
  right: 27%;
  left: auto;
  top: -13px;
}

.newsletter-content {
  padding-top: 0px;
  z-index: 9;
  position: relative;
}

.ticker-left {
  display: inline-block;
  font-weight: bold;
  min-width: 50%;
  margin-top: 0px;
  margin-bottom: -1rem !important;
}

.single-event-image span {
  display: block;
  font-family: "montserratsemi_bold";
  font-size: 26px;
  text-align: center;
}
.imagesNews_ {
  width: 236px;
  height: 234px;
}

.c-text a {
  cursor: pointer;
}
.ImagesFID {
  width: 1920px;
}
.contact-text p {
  background: #3498db;
  padding: 11px;
  color: #fff;
  border-radius: 10px;
}
.contact-text p span a {
  color: #fff;
}
.single-item-text {
  padding: 15px;
}
.course-details-content {
  margin: 0;
}
.single-item-text h5 {
  font-weight: bold;
}
.single-item-text p {
  margin-top: 10px;
  text-align: justify;
  line-height: 30px;
}
.single-course-details {
  padding: 10px 0 !important;
  margin-bottom: 0;
  box-shadow: -1px 8px 15px 5px #ccc;
}
.about-container h3 {
  color: #000;
}
.header-logo-menu.stick.semi-transparent,
.gallery-img,
.gallery-img .hover-effect,
.single-teacher-image > a:after {
  background: rgb(52 152 219 / 63%) none repeat scroll 0 0;
}
#gelery{
  padding: 10px;
}
#groupImages{
      background: #3498db;
    padding: 11px;
    color: #fff;
    border-radius: 30px;
}
</style>
