import { createRouter, createWebHistory } from 'vue-router'
const Home = () => import('../components/AppHome')
const ComingSoon = () => import('../components/ComingSoon')
const News = () => import('../components/News')
const NewsDetails = () => import('../components/News-Details')
const EventDetails = () => import('../components/Event-Details')
const Events = () => import('../components/Events')
const Univ = () => import('../components/Univ')
const Faculties = () => import('../components/Faculties') 
const Person =() => import('../components/Person') 
const Page =() => import('../components/Page') 
const NotFound =() => import('../components/NotFound') 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
   { path: '/:pathMatch(.*)*', component: NotFound },
  {
    path: '/ComingSoon',
    name: 'ComingSoon',
    component: ComingSoon
  },
  {
    path: '/News/:type',
    name: 'News-With-Type',
    component: News
  },
  {
    path: '/NewsDetails/:type/:id',
    name: 'NewsDetails-with-id-And-Type',
    component: NewsDetails
  },
  {
    path: '/Events/:type',
    name: 'Events-With-Type',
    component: Events
  },
  {
    path: '/Event-Details/:type/:id',
    name: 'Event-with-id-And-Type',
    component: EventDetails
  },
  {
    path: '/Univ/:type/:id',
    name: 'Univ-with-id-And-Type',
    component: Univ
  },
  {
    path: '/Faculties/:id',
    name: 'Faculties-with-id',
    component: Faculties
  },
  {
    path:'/Leader/:type/:id',
    name:'Leader-with-id-And-Type',
    component: Person
  },
  {
    path:'/Page/:type/:id',
    name:'Page-with-id-And-Type',
    component: Page,
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
