<template>
 <div class="loader" v-show="show">
      <div class="loader-inner">
        <img class="ImgLoading" src="../../public/img/logo/footer.png" />
        <img
          style="margin-top: 61px; margin-right: 10px"
          src="../../public/img/three-dots.svg"
        />
      </div>
    </div>
    
  <div class="breadcrumb-banner-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="breadcrumb-text">
            <h1 class="text-left">أرشيف الانشطة</h1>
            <div class="breadcrumb-bar">
              <ul class="breadcrumb text-left">
                <li><router-link to="/">الرئيسية</router-link></li>
                <li>أرشيف الانشطة</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="latest-area section-padding" style="padding: 50px 0 60px">
    <div class="container">
      <div class="row">
        <div v-for="event in Events" :key="event.postId" class="col-lg-4 col-md-6 col-12">
          <div class="single-event-item">
            <div class="single-event-image">
              <a href="javascript:void(0)">
                <img
                  v-bind:src="'http://api.yg-u.net/images/post/' + event.postImage"
                />
                <span
                  ><span>{{ event.day1 }}</span
                  >{{ event.nT_MONTH1 }}</span
                >
              </a>
            </div>
            <div class="single-event-text">
              <h6>
                <a href="javascript:void(0)" style="line-height: 2; text-align: justify"
                  >{{ event.postTitle }}
                </a>
              </h6>
              <div class="single-item-comment-view">
                <span><i class="zmdi zmdi-time"></i>{{ event.time1 }}</span>
                <span><i class="zmdi zmdi-pin"></i>{{ event.txtAdress1 }} </span>
              </div>
              <p style="text-align: justify">
                {{ event.postSubTitle }}
              </p>
          

              
                       <router-link  class="button-default"
                        :to="{
                          name: 'Event-with-id-And-Type',
                          params: {
                            type: $route.params.type,
                            id: event.postID,
                          },
                        }"
                      >
                     التفاصيل
               
                      </router-link>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      Events: [],
    };
  },
  mounted() {
    window.scroll(0, 0);
    var slef = this;
    var bodyFormData = new FormData();
    bodyFormData.append("check", "getPost");
    bodyFormData.append("Lang", "ar");
    bodyFormData.append("FbrnId", "");
    bodyFormData.append("Type", this.$route.params.type);
    bodyFormData.append("ParentId", "NTROOT0");
    bodyFormData.append("PostName", "");
    bodyFormData.append("Pno", "10");
    axios({
      method: "post",
      url: "http://api.yg-u.net/our_team/getPost",
      data: bodyFormData,
    })
      .then(function (response) {
        slef.Events = response.data;
        document.getElementById("VueMainJsNewTouch")?.remove();
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute("src", "./js/main.js");
        recaptchaScript.id = "VueMainJsNewTouch";
        document.head.appendChild(recaptchaScript);
      })
      .catch(function (response) {
        console.log("error", response);
      });
  },
};
</script>

<style scoped></style>
